<template>
  <q-page>
    <div class="row">
      <div
        v-if="resource"
        class="member-info col-sm-12"
      >
        <div class="top-sheet row">
          <div class="col-sm-10">
            <m-image
              :src="resource.picture"
              class="avatar"
            />
            <h4 class="text-white">
              {{ resource.display_name }}
              <h6>{{ resource.is_blocked ? 'Blocked' : 'Active' }}</h6>
            </h4>
            <div class="user-meta text-white">
              <p>{{ resource.email }}</p>
              <p>Joined {{ resource.created_at.date | date }}</p>
            </div>
          </div>
          <div class="col-sm-2">
            <user-meta-actions :staff="resource" />
          </div>
        </div>
        <q-tabs
          align="justify"
          class="shadow-2 text-white"
        >
          <q-route-tab
            v-if="canViewNote"

            to="notes"
            name="notes"
          >
            Notes
          </q-route-tab>
          <q-route-tab

            to="history"
            name="history"
          >
            History
          </q-route-tab>
        </q-tabs>
        <router-view
          :key="$route.fullPath"
          :member="resource"
        />
      </div>
    </div>
  </q-page>
</template>

<script type="text/javascript">
import store from 'store'
import date from 'utils/date-time'
import userMetaActions from '../backoffice-meta-actions'
import { MImage } from 'components/'

export default {
  name: 'StaffView',
  components: {
    userMetaActions,
    MImage
  },
  filters: {
    date: data => date.toShortDate(data)
  },
  computed: {
    canViewNote () {
      return this.$store.getters.hasPermission('users.notes.show')
    },
    resource () {
      return this.$store.getters['backoffice/getUser'](this.$route.params.username)
    }
  },
  beforeRouteEnter (to, from, next) {
    return store.dispatch('backoffice/loadUser', {
      username: to.params.username,
      params: {
        include: 'roles'
      }
    })
      .then(() => next())
      .catch(e => { console.log(e) })
  }
}
</script>

<style lang="stylus" scoped>
.avatar
  margin-right 15px
h4, h6
  display inline-block
  vertical-align middle
h6
  text-transform uppercase
.user-meta
  margin-left 70px
  line-height 1.5
.member-info-list
  width 30%
  position relative
  z-index 200
.top-sheet
  padding 1.6rem 2rem
  padding-bottom 0
  position relative
  z-index 150
  min-height 135px
.q-tabs
  position relative
  z-index 100
.tab-body
  height calc(100vh - 233px)
  overflow auto
</style>
