<template>
  <div
    v-if="round"
    class="container"
  >
    <q-btn
      :class="`text-${color || 'white'}`"
      round
      dense
      icon="cached"
      style="margin-right: 15px;"
      @click.stop="staffResetPassword()"
    >
      <q-tooltip>
        Reset Password
      </q-tooltip>
    </q-btn>
    <q-btn
      v-if="!staff.is_blocked"
      :class="`text-${color || 'white'}`"
      round
      dense
      icon="block"
      style="margin-right: 15px;"
      @click.stop="staffBlock()"
    >
      <q-tooltip>
        Block
      </q-tooltip>
    </q-btn>
    <q-btn
      v-if="staff.is_blocked"
      :class="`text-${color || 'white'}`"
      round
      dense
      icon="check_circle_outline"
      style="margin-right: 15px;"
      @click.stop="staffUnblock()"
    >
      <q-tooltip>
        Unblock
      </q-tooltip>
    </q-btn>
  </div>
  <div
    v-else
    class="container labelled"
  >
    <q-btn
      :class="`text-${color || 'white'}`"
      color="light"
      label="Reset Password"
      style="margin-top: 15px;"
      @click.stop="staffResetPassword()"
    />
    <q-btn
      v-if="!staff.is_blocked"
      :class="`text-${color || 'white'}`"
      color="light"
      label="Block staff"
      style="margin-top: 15px;"
      @click.stop="staffBlock()"
    />
    <q-btn
      v-if="staff.is_blocked"
      :class="`text-${color || 'white'}`"
      color="light"
      label="Unblock staff"
      style="margin-top: 15px;"
      @click.stop="staffUnblock()"
    />
  </div>
</template>

<script type="text/javascript">
export default {
  props: {
    staff: {
      type: Object
    },
    color: {
      type: String
    },
    round: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    staffUnblock () {
      this.$q.dialog({
        title: 'Confirm',
        message: 'Are you sure you want to unblock this staff member',
        ok: 'Confirm',
        cancel: 'Cancel'
      })
        .onOk(() => {
          this.$store.dispatch('backoffice/unblockUser', {
            user: this.staff
          })
        })
    },
    staffBlock () {
      this.$q.dialog({
        title: 'Confirm',
        message: 'Are you sure you want to block this staff member',
        ok: 'Confirm',
        cancel: 'Cancel'
      })
        .onOk(() => {
          this.$store.dispatch('backoffice/blockUser', {
            user: this.staff
          })
        })
    },
    staffResetPassword () {
      this.$q.dialog({
        title: 'Confirm',
        message: "Are you sure you want to reset this member's password",
        ok: 'Confirm',
        cancel: 'Cancel'
      }).onOk(() => {
        this.$store.dispatch('members/resetUserPassword', {
          user: this.staff
        })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.container
  display inline-block
.labelled
  display flex
  flex-direction column
  justify-content center
  align-items center
  .q-btn
    display block
    width 182px
</style>
