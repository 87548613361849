<template>
  <m-infinite-activity
    :getter="load"
    height="calc(100vh - 247px)"
  />
</template>

<script>
import { activities as getUserActivity } from 'api/members'
import { MInfiniteActivity } from 'components/'

export default {
  components: { MInfiniteActivity },
  methods: {
    async load (filters) {
      return getUserActivity(this.$route.params.username, filters)
    }
  }
}
</script>
