<template>
  <div class="create-modal-wrapper">
    <q-btn
      fab
      class="absolute btn-create-member"
      color="positive"
      icon="add"
      style="right: 50px; top: 175px;"
      @click="open"
    />

    <q-dialog
      v-model="modal"
      class="create-backoffice"
      @ok="create"
      @cancel="() => error = null"
    >
      <q-card style="width: 500px; max-width: 50vw">
        <q-card-section>
          <div class="text-h5">
            Add back office user
          </div>
        </q-card-section>
        <q-card-section>
          <m-error-block :error="error" />

          <q-select
            v-model="title"
            :options="selects('titles')"
            stack-label
            label="Title"
            emit-value
          />

          <q-input
            v-model="first_name"
            stack-label
            label="First Name"
          />

          <q-input
            v-model="last_name"
            stack-label
            label="Last Name"
          />

          <q-input
            v-model="email"
            type="email"
            stack-label
            label="Email"
          />

          <q-input
            v-model="password"
            stack-label
            label="Password"
            type="password"
          />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            color="primary"
            label="Submit"
            @click="create"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import { required, email, minLength } from 'vuelidate/lib/validators'
import { MErrorBlock } from 'components/'
export default {
  name: 'StaffCreate',
  components: { MErrorBlock },
  data () {
    return {
      error: null,
      modal: false,
      email: null,
      password: null,
      title: null,
      first_name: null,
      last_name: null
    }
  },
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(8) },
    title: { required },
    first_name: { required },
    last_name: { required }
  },
  computed: {
    ...mapGetters({
      selects: 'members/getSelect'
    }),
    emailError () {
      if (!this.$v.email.required) {
        return 'Email is required'
      } else if (!this.$v.email.email) {
        return 'Email must be a valid email'
      }
      return ''
    },
    passwordError () {
      if (!this.$v.password.required) {
        return 'Password is required'
      } else if (!this.$v.password.minLength) {
        return 'Password must be at least 8 characters'
      }
      return ''
    }
  },
  methods: {
    create (email) {
      this.error = null
      this.$v.$touch()
      if (!this.$v.$error) {
        this.$store.dispatch('backoffice/createUser', {
          email: this.email,
          password: this.password,
          title: this.title,
          first_name: this.first_name,
          last_name: this.last_name
        }).then(() => {
          this.$emit('created', this.value)
          this.close()
          this.$q.notify({
            message: `User created successfully`,
            color: 'positive',
            icon: 'mdi-check'
          })
        }).catch(e => {
          this.error = e
          this.$q.notify({
            message: e.message || e.title,
            color: 'negative',
            icon: 'clear'
          })
        })
      }
    },
    open () {
      this.modal = true
    },
    close () {
      this.clear()
      this.modal = false
    },
    clear () {
      this.error = null
      this.email = null
      this.password = null
      this.title = null
      this.first_name = null
      this.last_name = null
    }
  }
}
</script>

<style lang="stylus">
.create-backoffice .modal-content
  width 22%
  min-width 300px
.create-backoffice .modal-scroll
  max-height 500px!important
.buttons
  padding 10px 16px
</style>
