<template>
  <q-page>
    <list />
  </q-page>
</template>

<script type="text/javascript">
import list from './list'
export default {
  name: 'BackOffice',
  components: {
    list
  }
}
</script>
