<template>
  <q-card
    v-ripple
    :class="selected === staff.username ? 'bg-deep-purple-4 text-white shadow-3' : 'bg-white shadow-1'"
    class="cursor-pointer staff"
    @click.native="view()"
  >
    <q-card-section class="row">
      <div class="col-sm-4 tcell">
        <q-avatar class="avatar">
          <q-img ratio="1" :src="staff.picture" />
        </q-avatar>
        <div>{{ staff.display_name }}</div>
      </div>
      <div class="col-sm-4 tcell">
        <span>{{ staff.email }}</span>
      </div>
      <div class="col-sm-4 tcell row">
        <div class="col-sm-5 text-white">
          <span class="text-white"><q-chip
            :small="true"
            :color="staff.is_blocked ? 'negative' : 'positive'"
            class="text-white"
          >{{ staff.is_blocked ? 'Blocked' : 'Active' }}</q-chip></span>
        </div>
        <div class="col-sm-7 actions" @click.stop>
          <div class="staff-menu">
            <q-btn
              :class="'text-primary'"
              flat
              round
              dense
              icon="more_vert"
              @click.stop="showPopover = !showPopover"
            />
            <q-menu v-model="showPopover">
              <q-list
                link
                style="min-width: 100px"
              >
                <q-item
                  v-for="item in menuItems"
                  :key="item.label"
                  v-close-popup
                  v-ripple
                  clickable
                  @click.native="$router.push(`/sdc/${staff.username}/${item.tab}`)"
                >
                  <q-item-section>{{ item.label }}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </div>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  props: {
    staff: Object,
    selected: String
  },
  data () {
    return {
      showPopover: false,
      menuItems: [
        {
          label: 'Notes',
          tab: 'notes'
        },
        {
          label: 'History',
          tab: 'history'
        }
      ]
    }
  },
  methods: {
    view () {
      this.$router.push({ hash: '/user-settings', query: { username: this.staff.username } })
    }
  }
}
</script>

<style lang="stylus" scoped>
.tcell
  display: flex;
  align-items: center;
  justify-content: flex-start;

.avatar
  display none
  margin-right 15px

.staff-menu
  position: absolute;
  top: 0px;
  right: 12px;

.actions
  height 50px
.actions > *
  margin-top 8px

.staff
  position relative
  width 77%

.small-staff-list
  .staff
    width 96%
  .small-hide
    display none
  .staff
    max-height 70px

@media screen and (min-width: 930px)
  .avatar
    display block

</style>
