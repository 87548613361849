<template>
  <div class="backoffice-list">
    <m-resource-top-sheet
      :columns="columns"
      title="SDC"
    >
      <template slot="tools">
        <div class="row tools q-col-gutter-sm">
          <div class="col-sm-8">
            <q-input
              v-model="searchTerm"
              label="Search for a staff member"
              class="search"
              debounce="500"
              color="primary"
              @input="runSearch"
            />
          </div>
        </div>
        <staff-create @created="onCreate" />
      </template>
    </m-resource-top-sheet>

    <q-scroll-area class="index-list">
      <m-infinite-scroll
        :load="filter"
        :page="filters.page"
        :pagination="pagination"
        icon="person"
        resource="staff"
      >
        <div
          v-for="s in staff"
          :key="s.username"
          class="row-item"
        >
          <staff-card
            :staff="s"
            :selected="selected"
          />
        </div>
      </m-infinite-scroll>
    </q-scroll-area>
  </div>
</template>

<script type="text/javascript">
import _ from 'lodash'
import store from 'store'
import staffCard from './staff-card'
import authentication from 'mixins/authentication'
import staffCreate from './staff-create'
import { MResourceTopSheet, MInfiniteScroll } from 'components/'

export default {
  name: 'Backoffice',
  components: {
    staffCard,
    staffCreate,
    MResourceTopSheet,
    MInfiniteScroll
  },
  mixins: [authentication],
  props: {
    small: {
      type: Boolean
    },
    selected: {
      type: String
    }
  },
  data () {
    return {
      searchTerm: '',
      staff: [],
      block_statuses: [],
      filters: {
        page: 0,
        limit: 10,
        search: ''
      },
      pagination: null,
      columns: ['Name', 'Email', 'Status']
    }
  },
  created () {
    store.dispatch('backoffice/reset')
    this.block_statuses = this.$store.getters['backoffice/getSelect']('blockStatuses')
  },
  methods: {
    clear () {
      this.filters.page = 0
      this.staff = []
    },
    runSearch (search) {
      if (search.length || (!search.length && this.filters.search.length)) {
        this.clear()
        this.filters.search = search
      }
    },
    filter (page) {
      this.filters.include = 'roles'
      this.filters.page = page
      return store.dispatch('backoffice/loadUsers', {
        params: _.pickBy(this.filters)
      })
        .then(response => {
          this.staff = this.staff.concat(response.data)
          this.pagination = response.meta.pagination
        })
    },
    onCreate () {
      this.clear()
      this.filter(1)
    }
  }
}
</script>

<style lang="stylus" scoped>
.index-list
  padding 0 3rem
  height calc(100vh - 279px)
  .row-item:first-child
    margin-top 16px

.col-head
  display: flex;
  justify-content: space-between;
  padding-right 40px

.tools
  width 50%

.index-head, .individual
  width 75%

.row-item
  width 100%

.individual
  max-height 82px
  position relative
  //transition background-color .5s

</style>
